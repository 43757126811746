.card1 {
    width: 100%;
    max-width: 300px;
    min-width: 200px;
    height: 250px;
    background-color: #909396ad;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
    border: 2px solid rgba(7, 7, 7, 0.12);
    font-size: 16px;
    transition: all 0.3s ease;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
  }
  
  .icon {
    margin: 0 auto;
    width: 100%;
    height: 80px;
    max-width: 80px;
    background: linear-gradient(90deg, #1174f5 0%, #f06262 40%, rgba(0, 0, 0, 0.28) 60%);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.8s ease;
    background-position: 0px;
    background-size: 200px;
  }
  
  .icon svg {
    fill: white;
  }
  
  .card1 .title {
    width: 100%;
    margin: 0;
    text-align: center;
    margin-top: 30px;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 4px;
  }
  
  .card1 .text {
    width: 80%;
    margin: 0 auto;
    font-size: 13px;
    text-align: center;
    margin-top: 20px;
    color: white;
    font-weight: 200;
    letter-spacing: 2px;
    opacity: 0;
    max-height: 0;
    transition: all 0.3s ease;
  }
  
  .card1:hover {
    height: 270px;
  }
  
  .card1:hover .text {
    transition: all 0.3s ease;
    opacity: 1;
    max-height: 40px;
  }
  
  .card1:hover .icon {
    background-position: -120px;
    transition: all 0.3s ease;
  }
  
  .card1:hover .icon svg path {
    fill: url('#gradientColor');
    transition: all 0.3s ease;
  }

